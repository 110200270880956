<div class="container">
  <div class="privacy-policy-content">
    <h6>AppWorld</h6>
    <p>
      AppWorld collects <strong>Your Personal Data</strong> purely for progress
      tracking. That is our privacy policy in a nutshell. This Privacy Policy
      describes Our policies and procedures on the collection, use and
      disclosure of Your information when You use the Application and tells You
      about Your privacy rights and how the law protects You. By using the
      Application, You agree to the collection and use of information in
      accordance with this Privacy Policy.
    </p>
    <h6>Interpretation and Definitions</h6>
    <p>
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in
      plural.<br />
      For the purposes of this Privacy Policy:<br />
      <br />
      <strong>Account</strong> means a unique account created for You to access
      our Service or parts of our Service.<br />
      <strong>Affiliate</strong> means an entity that controls, is controlled by
      or is under common control with a party, where “control” means ownership
      of 50% or more of the shares, equity interest or other securities entitled
      to vote for election of directors or other managing authority.<br />
      <strong>Application</strong> means the software program provided by the
      AppWorld downloaded by You on any electronic device, named AppWorld.<br />
      <strong>Business</strong>, for the purpose of the CCPA (California
      Consumer Privacy Act), refers to the Company as the legal entity that
      collects Consumers' personal information and determines the purposes and
      means of the processing of Consumers' personal information, or on behalf
      of which such information is collected and that alone, or jointly with
      others, determines the purposes and means of the processing of consumers'
      personal information, that does business in the State of California.<br />
      <strong>Company</strong> (referred to as either “the Company”, “We”, “Us”
      or “Our” in this Agreement) refers to AppWorld. For the purpose of the
      GDPR, the Company is the Data Controller.<br />
      <strong>Consumer</strong>, for the purpose of the CCPA (California
      Consumer Privacy Act), means a natural person who is a California
      resident. A resident, as defined in the law, includes (1) every individual
      who is in the USA for other than a temporary or transitory purpose, and
      (2) every individual who is domiciled in the USA who is outside the USA
      for a temporary or transitory purpose.<br /><strong>Cookies</strong> are
      small files that are placed on Your computer, mobile device or any other
      device by a website, containing the details of Your browsing history on
      that website among its many uses.<br /><strong>Country</strong> refers to:
      Switzerland<br /><strong>Data Controller</strong>, for the purposes of the
      GDPR (General Data Protection Regulation), refers to the Company as the
      legal person which alone or jointly with others determines the purposes
      and means of the processing of Personal Data.<br /><strong>Device</strong>
      means any device that can access the Service such as a computer, a
      cellphone or a digital tablet.<br /><strong>Do Not Track (DNT)</strong> is
      a concept that has been promoted by US regulatory authorities, in
      particular the U.S. Federal Trade Commission (FTC), for the Internet
      industry to develop and implement a mechanism for allowing internet users
      to control the tracking of their online activities across websites.<br /><strong
        >Personal Data</strong
      >
      is any information that relates to an identified or identifiable
      individual. For the purposes for GDPR, Personal Data means any information
      relating to You such as a name, an identification number, location data,
      online identifier or to one or more factors specific to the physical,
      physiological, genetic, mental, economic, cultural or social identity. For
      the purposes of the CCPA, Personal Data means any information that
      identifies, relates to, describes or is capable of being associated with,
      or could reasonably be linked, directly or indirectly, with You.<br /><strong
        >Sale</strong
      >, for the purpose of the CCPA (California Consumer Privacy Act), means
      selling, renting, releasing, disclosing, disseminating, making available,
      transferring, or otherwise communicating orally, in writing, or by
      electronic or other means, a Consumer's Personal information to another
      business or a third party for monetary or other valuable consideration.<br /><strong
        >Service</strong
      >
      refers to the Application or the Website or both.<br /><strong
        >Service Provider</strong
      >
      means any natural or legal person who processes the data on behalf of the
      Company. It refers to third-party companies or individuals employed by the
      Company to facilitate the Service, to provide the Service on behalf of the
      Company, to perform services related to the Service or to assist the
      Company in analyzing how the Service is used. For the purpose of the GDPR,
      Service Providers are considered Data Processors.<br /><strong
        >Third-party</strong
      >
      Social Media Service refers to any website or any social network website
      through which a User can log in or create an account to use the
      Service.<br /><strong>Usage Data</strong> refers to data collected
      automatically, either generated by the use of the Service or from the
      Service infrastructure itself (for example, the duration of a page
      visit).<br /><strong>Website</strong> refers to the AppWorld website,
      accessible from https://appworldsa.com<br /><strong>You</strong> means the
      individual accessing or using the Service, or the company, or other legal
      entity on behalf of which such individual is accessing or using the
      Service, as applicable.<br />Under <strong>GDPR</strong> (General Data
      Protection Regulation), You can be referred to as the Data Subject or as
      the User as you are the individual using the Service.
    </p>
    <h6>Types of Data Collected</h6>
    <p>
      While using the Application,
      <strong
        >we collect personally identifiable information if you create an account
        to track your progress and recover your progress if you switch to a
        different device.</strong
      >
      We do not access nor collect any information from the keyboard itself.
      Everything you type remains fully on your device.
    </p>
    <h6>Privacy Policy for Access to Google APIs</h6>
    <p>
      AppWorld is using the Google API to make it easier to create and login to
      your account and for access to Google Drive storage. We use AppWorld
      Protal Project through Google Cloud Platform. This integration is governed
      by common policies. Your use of Google APIs with AppWorld is subject to
      each API's respective terms of service. See
      <a href="https://developers.google.com/terms" target="_blank"
        >Google APIs Terms of Service.</a
      >
    </p>
    <p>
      We will use your data to provide you with the services you requested, such
      as email notifications, newsletters and uploading files to our file
      server.
    </p>
    <p>
      We will not sell your data to third parties, but we may share it with our
      partners who help us provide our services.
    </p>
    <h6>Use of Your Personal Data</h6>
    <div>
      <p>AppWorld may use Personal Data for the following purposes:</p>
      <ul>
        <li>
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </li>
        <li>
          To manage Your Account: to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </li>
        <li>
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </li>
        <li>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </li>
        <li>
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </li>
        <li>
          To manage Your requests: To attend and manage Your requests to Us.
        </li>
        <li>
          For business transfers: We may use Your information to evaluate or
          conduct a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of Our assets,
          whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which Personal Data held by Us about our
          Service users is among the assets transferred.
        </li>
        <li>
          For other purposes: We may use Your information for other purposes,
          such as data analysis, identifying usage trends, determining the
          effectiveness of our promotional campaigns and to evaluate and improve
          our Service, products, services, marketing and your experience.
        </li>
      </ul>
      <p>We may share Your personal information in the following situations:</p>
      <ul>
        <li>
          With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, for
          payment processing, to contact You.
        </li>
        <li>
          For business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of Our business to another company.
        </li>
        <li>
          With Affiliates: We may share Your information with Our affiliates, in
          which case we will require those affiliates to honor this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </li>
        <li>
          With business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </li>
        <li>
          With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside.
        </li>
        <li>
          With Your consent: We may disclose Your personal information for any
          other purpose with Your consent.
        </li>
      </ul>
    </div>
    <h6>Retention of Your Personal Data</h6>
    <p>
      AppWorld will retain Your Personal Data only for as long as is necessary
      for the purposes set out in this Privacy Policy. We will retain and use
      Your Personal Data to the extent necessary to comply with our legal
      obligations (for example, if we are required to retain your data to comply
      with applicable laws), resolve disputes, and enforce our legal agreements
      and policies.
    </p>
    <p>
      AppWorld will also retain Usage Data for internal analysis purposes. Usage
      Data is generally retained for a shorter period of time, except when this
      data is used to strengthen the security or to improve the functionality of
      Our Service, or We are legally obligated to retain this data for longer
      time periods.
    </p>
    <h6>Deactivate your account</h6>
    <p>
      If you no longer wish to use our Services, you may have the option to deactivate your account through your account settings. If this option is available, you can find it within your account preferences. Deactivating your account does not remove your past activity, which may still be visible to other users depending on your interactions within the Services. For details on deleting your information, see below.
    </p>
    <h6>Delete your information</h6>
    <p>
      Our Services provide tools that allow you to delete information about yourself. For example, you can remove content associated with you using keyword search and editing tools or update specific details in your profile settings. You may also delete your account through the editing tools available on profile settings and may request your account to be deleted by sending an email to <a href="mailto:support@appworldsa.com">support&#64;appworldsa.com</a>
    </p>
    <h6>Your Rights under the GDPR</h6>
    <p>
      The Company undertakes to respect the confidentiality of Your Personal
      Data and to guarantee You can exercise Your rights. You have the right
      under this Privacy Policy, and by law if You are within the EU, to:
    </p>
    <ul>
      <li>
        Request access to Your Personal Data. The right to access, update or
        delete the information We have on You. Whenever made possible, you can
        access, update or request deletion of Your Personal Data directly within
        Your account settings section. If you are unable to perform these
        actions yourself, please contact Us to assist You. This also enables You
        to receive a copy of the Personal Data We hold about You.
      </li>
      <li>
        Request correction of the Personal Data that We hold about You. You have
        the right to to have any incomplete or inaccurate information We hold
        about You corrected.
      </li>
      <li>
        Object to processing of Your Personal Data. This right exists where We
        are relying on a legitimate interest as the legal basis for Our
        processing and there is something about Your particular situation, which
        makes You want to object to our processing of Your Personal Data on this
        ground. You also have the right to object where We are processing Your
        Personal Data for direct marketing purposes.
      </li>
      <li>
        Request erasure of Your Personal Data. You have the right to ask Us to
        delete or remove Personal Data when there is no good reason for Us to
        continue processing it.
      </li>
      <li>
        Request the transfer of Your Personal Data. We will provide to You, or
        to a third-party You have chosen, Your Personal Data in a structured,
        commonly used, machine-readable format. Please note that this right only
        applies to automated information which You initially provided consent
        for Us to use or where We used the information to perform a contract
        with You.
      </li>
      <li>
        Withdraw Your consent. You have the right to withdraw Your consent on
        using your Personal Data. If You withdraw Your consent, We may not be
        able to provide You with access to certain specific functionalities of
        the Website.
      </li>
    </ul>
    <h6>Exercising of Your GDPR Data Protection Rights</h6>
    <p>
      You may exercise Your rights of access, rectification, cancellation and
      opposition by contacting Us. Please note that we may ask You to verify
      Your identity before responding to such requests. If You make a request,
      We will try our best to respond to You as soon as possible.You have the
      right to complain to a Data Protection Authority about Our collection and
      use of Your Personal Data. For more information, if You are in the
      European Economic Area (EEA), please contact Your local data protection
      authority in the EEA.
    </p>
    <h6>Your Rights under the CCPA</h6>
    <p>
      Under this Privacy Policy, and by law if You are a resident of California,
      You have the following rights:
    </p>
    <ul>
      <li>
        The right to notice. You must be properly notified which categories of
        Personal Data are being collected and the purposes for which the
        Personal Data is being used.
      </li>
      <li>
        The right to access / the right to request. The CCPA permits You to
        request and obtain from the Company information regarding the disclosure
        of Your Personal Data that has been collected in the past 12 months by
        the Company or its subsidiaries to a third-party for the third party's
        direct marketing purposes.
      </li>
      <li>
        The right to say no to the sale of Personal Data. You also have the
        right to ask the Company not to sell Your Personal Data to third
        parties. You can submit such a request by visiting our “Do Not Sell My
        Personal Information” section or web page.
      </li>
      <li>
        The right to know about Your Personal Data. You have the right to
        request and obtain from the Company information regarding the disclosure
        of the following:<br />- The categories of Personal Data collected<br />-
        The sources from which the Personal Data was collected<br />- The
        business or commercial purpose for collecting or selling the Personal
        Data<br />- Categories of third parties with whom We share Personal
        Data<br />- The specific pieces of Personal Data we collected about You
      </li>
      <li>
        The right to delete Personal Data. You also have the right to request
        the deletion of Your Personal Data that have been collected in the past
        12 months.
      </li>
      <li>
        The right not to be discriminated against. You have the right not to be
        discriminated against for exercising any of Your Consumer's rights,
        including by:<br />- Denying goods or services to You<br />- Charging
        different prices or rates for goods or services, including the use of
        discounts or other benefits or imposing penalties<br />- Providing a
        different level or quality of goods or services to You<br />- Suggesting
        that You will receive a different price or rate for goods or services or
        a different level or quality of goods or services.
      </li>
    </ul>
    <h6>Exercising Your CCPA Data Protection Rights</h6>
    <p>
      In order to exercise any of Your rights under the CCPA, and if you are a
      California resident, You can email or call us or visit our “Do Not Sell My
      Personal Information” section or web page.<br />The Company will disclose
      and deliver the required information free of charge within 45 days of
      receiving Your verifiable request. The time period to provide the required
      information may be extended once by an additional 45 days when reasonable
      necessary and with prior notice.
    </p>
    <h6>Do Not Sell My Personal Information</h6>
    <p>We do not sell personal information.</p>
    <h6>Children's Privacy</h6>
    <p>
      The Application is not directed to individuals under the age of thirteen
      (13), but may be used by children under the age of 13 and we do not
      knowingly collect personal data from children under the age of 13.
    </p>
    <h6>Links to Other Websites</h6>
    <p>
      Our Application may contain links to other websites that are not operated
      by Us. If You click on a third party link, You will be directed to that
      third party’s site. We strongly advise You to review the Privacy Policy of
      every site You visit.We have no control over and assume no responsibility
      for the content, privacy policies or practices of any third party sites or
      services.
    </p>
    <h6>Changes to this Privacy Policy</h6>
    <p>
      If you have any questions about this Privacy Policy, You can contact us:
      <a href="mailto:support@appworldsa.com">support&#64;appworldsa.com</a>
    </p>
  </div>
</div>
